import moment from 'moment';

function toNumber(val) {
    let num = Number(val);
    if (isNaN(num)) {
        return 0;
    } else {
        return num;
    }
}

function doesOverlap(e1, e2) {
    var e1start = moment(e1.start).valueOf();
    var e1end = moment(e1.end).valueOf();
    var e2start = moment(e2.start).valueOf();
    var e2end = moment(e2.end).valueOf();

    return (e1start > e2start && e1start < e2end ||
        e2start > e1start && e2start < e1end);
}

function checkOverlap(items, maxOverlap, itemType) {
    // Sort the items by start time
    items.sort((a, b) => moment(a.start).valueOf() - moment(b.start).valueOf());

    let overlapCount = 0;

    for (let i = 0; i < items.length; i++) {
        for (let j = i + 1; j < items.length; j++) {
            // Break the loop if the start time of the next item is later than the end time of the current item
            if (moment(items[j].start).isSameOrAfter(items[i].end)) {
                break;
            }

            if (doesOverlap(items[i], items[j])) {
                overlapCount++;

                // Exit if the maximum overlap is reached
                if (overlapCount > maxOverlap) {
                    let errorMessage = maxOverlap === 0 ?
                        `No overlap is allowed for ${itemType}, but found ${overlapCount} overlap(s)` :
                        `Maximum ${itemType} overlap of ${maxOverlap} exceeded with ${overlapCount} overlap(s)`;

                    throw new Error(errorMessage);
                }
            }
        }
    }
}

const generateBalanceChanges = (data) => {
    let currentBalance = toNumber(data.balance);
    let balanceChanges = [];
    const timeSerieEndDate = data.serieEndDate;

    // Provide default values for deliveries and productions
    let deliveries = data.deliveries || [];
    let productions = data.productions || [];

    let events = [...deliveries.map(event => {
        return { date: event.start, quantity: -toNumber(event.quantity) };
    }), ...productions.map(event => {
        return { date: event.end, quantity: toNumber(event.quantity) };
    })];

    // Remove events that do not change the balance
    events = events.filter(event => event.quantity !== 0);

    // Group events by date; to avoid multiple change events get generated, we cosolidate the changes.
    const groupedEvents = {};
    events.forEach(event => {
        if (!groupedEvents[event.date]) {
            groupedEvents[event.date] = event.quantity;
        } else {
            groupedEvents[event.date] += event.quantity;
        }
    });

    // Convert back to events array
    events = Object.entries(groupedEvents).map(([date, quantity]) => ({ date, quantity }));

    events.sort((a, b) => new Date(a.date) - new Date(b.date));

    if (events.length) {
        balanceChanges.push({ start: data.balanceDate, end: events[0].date, balance: currentBalance });

        for (let i = 0; i < events.length; i++) {
            let event = events[i];
            currentBalance += toNumber(event.quantity);
            let nextEvent = events[i + 1];
            let end;

            if (nextEvent) {
                end = nextEvent.date;
            } else {
                end = timeSerieEndDate ? moment(timeSerieEndDate).endOf('day').format() : moment(event.date).add(1, 'month').endOf('day').format();
            }

            balanceChanges.push({ start: event.date, end: end, balance: currentBalance });
        }
    } else {
        balanceChanges.push({ start: data.balanceDate, end: timeSerieEndDate ? moment(timeSerieEndDate).endOf('day').format() : moment().add(1, 'month').endOf('day').format(), balance: currentBalance });
    }

    return balanceChanges;
}

const generateGanttRows = (groups) => {
    return groups.flatMap((group, index) => {
        const productInternalId = `#${index + 1}`;
        const product = group.product;
        return [
            {
                id: 3 * index + 1,
                internalId: productInternalId,
                name: `${product.name} (${product.measureType == "Weight"
                    ? product.measureWeight
                    : product.measureUnit
                    })`,
                productName: product.name,
                type: "balance",
                productId: product.id,
                measureType: product.measureType,
                measureUnit: product.measureUnit,
                measureWeight: product.measureWeight,
                product
            },
            {
                id: 3 * index + 2,
                internalId: `${productInternalId}.1`,
                name: "Delivery",
                productName: product.name,
                type: "delivery",
                productId: product.id,
            },
            {
                id: 3 * index + 3,
                internalId: `${productInternalId}.2`,
                name: "Production",
                productName: product.name,
                type: "production",
                productId: product.id,
            },
        ];
    });
}

const generateGanttItem = (group, row) => {
    let items = [];
    let rowId = row.id;
    const product = group.product;

    switch (row.type) {
        case 'balance':
            let balanceChanges = generateBalanceChanges(group);
            for (let [index, balanceChange] of balanceChanges.entries()) {
                const balance = Number(balanceChange.balance).toFixed(2);
                items.push({
                    index,
                    product,
                    rowId: rowId,
                    type: 'balance',
                    balance,
                    label: `${balance}`,
                    style: { background: balanceChange.balance > 0 ? "#24abf2" : "rgba(244, 67, 54, 0.5)" },
                    time: {
                        start: moment(balanceChange.start),
                        end: moment(balanceChange.end),
                    }
                });
            }
            break;
        case 'delivery':
            if (group.deliveries) {
                checkOverlap(group.deliveries, group.maxDeliveryOverlap, 'delivery');

                for (let [index, delivery] of group.deliveries.entries()) {
                    items.push({
                        index,
                        productId: product.id,
                        productName: product.name,
                        rowId: rowId,
                        deliveryId: delivery.id,
                        type: 'delivery',
                        quantity: delivery.quantity,
                        label: `${delivery.quantity}`,
                        style: { background: "rgba(255, 152, 0, 0.5)" },
                        time: {
                            start: moment(delivery.start),
                            end: moment(delivery.end),
                        },
                        "moveable": false,
                        "resizable": false
                    });
                }
            }
            break;
        case 'production':
            if (group.productions) {
                checkOverlap(group.productions, group.maxProductionOverlap, 'production');

                for (let [index, production] of group.productions.entries()) {
                    items.push({
                        index,
                        productId: product.id,
                        productName: product.name,
                        rowId: rowId,
                        productionId: production.id,
                        type: 'production',
                        quantity: production.quantity,
                        label: `${production.quantity}`,
                        style: { background: "rgba(0, 200, 83, 0.5)" },
                        time: {
                            start: moment(production.start),
                            end: moment(production.end),
                        },
                        "moveable": false,
                        "resizable": false
                    });
                }
            }
            break;
    }

    return items;
}


const generateGanttItems = (productGroups, ganttRows) => {
    let items = [];

    for (let row of ganttRows) {
        let dataGroup = productGroups.find(g => g.product.id == row.productId)
        items = items.concat(generateGanttItem(dataGroup, row));
    }

    return items;
}



export {
    generateBalanceChanges,
    generateGanttItems,
    generateGanttItem,
    generateGanttRows
}