<template>
  <div class="ma-4">
    <v-expansion-panels v-model="panel">
      <v-expansion-panel>
        <v-expansion-panel-header>Parameters</v-expansion-panel-header>
        <v-expansion-panel-content>
          <page-params
            pageName="flowControl"
            vuexName="flowControlParameters"
            :parameters="parameters"
            @onLoad="loadedSavedParams"
          />

          <div class="d-flex justify-space-around">
            <date-picker
              outlined
              dense
              :label="$vuetify.lang.t('$vuetify.base.startDate')"
              :defaultNowOnNull="false"
              :show-hint="false"
              :show-icon="false"
              v-model="startDate"
              class="mx-2"
            />
            <date-picker
              outlined
              dense
              :label="$vuetify.lang.t('$vuetify.base.endDate')"
              :defaultNowOnNull="false"
              :show-hint="false"
              :show-icon="false"
              v-model="endDate"
              class="mx-2"
            />
          </div>

          <product-select
            label="Search Products"
            v-model="parameters.selectedProducts"
            multiple
            return-object
            @onLoad="initProductList"
          />

          <div class="d-flex justify-center my-4">
            <v-btn color="primary" @click="onSubmit" :loading="loading">
              Submit
            </v-btn>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-divider class="my-4" />

    <div v-if="loaded" style="position: relative">
      <div
        v-if="rows && rows.length > 0"
        class="menu d-flex justify-space-between"
      >
        <div>
          <v-text-field
            label="Filter Products"
            outlined
            dense
            prepend-icon="mdi-magnify"
            v-model="filterProductName"
            clearable
          />
        </div>
        <v-slider
          class="mx-6"
          v-model="zoom"
          :min="8"
          :max="16"
          append-icon="mdi-magnify-plus-outline"
          prepend-icon="mdi-magnify-minus-outline"
          thumb-label
          @click:append="zoomIn"
          @click:prepend="zoomOut"
        />
        <div>
          <v-btn-toggle v-model="displayRows" dense multiple>
            <v-btn value="delivery">
              <v-icon>mdi-truck-delivery</v-icon>
            </v-btn>

            <v-btn value="production">
              <v-icon>mdi-account-hard-hat</v-icon>
            </v-btn>
          </v-btn-toggle>
        </div>
      </div>

      <VueDjGantt
        v-if="rows && rows.length > 0"
        :from="startDate"
        :to="endDate"
        :list="list"
        :rows="filteredRows"
        :items="filteredItems"
        locale="en"
        :height="viewportHeight - 300"
        :zoomLevel="zoom"
        @row="selectedRow"
        @cell="selectedCell"
        @dblclick-cell="doubleClickedCell"
        @dblclick-item="doubleClickedItem"
        @time-slot="selectedTimeSlot"
        @zoom="zoomChanged"
      >
        <template v-slot:item-label="item">
          <span v-if="item.item.type == 'balance'">
            {{ parseFloat(item.item.balance) | formatNumber }}
            {{ item.item.product.measureWeight }}
          </span>
          <span v-else>
            {{ item.item.quantity | formatNumber }}
          </span>
        </template>
        <template v-slot:row-label="item">
          <div
            v-if="item.row.type == 'balance'"
            class="d-flex justify-space-between"
          >
            <v-icon class="my-auto mr-2 bg-icon">
              mdi-vector-difference
            </v-icon>
            <div class="flex-grow-1 text-ellipsis">{{ item.row.name }}</div>
            <span class="mx-2">
              <row-support-menu
                @open-dialog="openMenuDialog"
                :rowItem="item"
                :dateRange="dateRange"
              />
            </span>
          </div>
          <div v-else class="d-flex justify-space-between">
            <v-icon class="my-auto mr-2 bg-icon">
              {{
                item.row.type == "delivery"
                  ? "mdi-truck-delivery"
                  : "mdi-account-hard-hat"
              }}
            </v-icon>
            <div class="flex-grow-1 text-ellipsis ml-4">
              {{ item.row.name }}
            </div>
          </div>
        </template>
      </VueDjGantt>

      <div v-else class="text-center font-weight-light">No data found</div>

      <v-overlay :value="loading" absolute>
        <v-progress-circular indeterminate></v-progress-circular>
      </v-overlay>
    </div>

    <v-dialog v-model="dialog">
      <v-card>
        <v-card-title class="justify-end">
          <v-btn icon @click="dialog = false">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <pre v-if="dialogMode == 'debug'"> {{ dialogContent }} </pre>
          <component
            v-else
            :is="dialogMode"
            v-bind="dialogData"
            @on-success="onDialogSuccess"
            @on-close="onDialogClose"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
const VueDjGantt = () => import("@/components/common/VueDjGantt.vue");
const RowSupportMenu = () =>
  import("@/components/flowControl/RowSupportMenu.vue");
const ProductSelect = () => import("@/components/product/ProductSelect.vue");
const DeliverySchedule = () =>
  import("@/components/flowControl/DeliverySchedule.vue");
const ProductionSchedule = () =>
  import("@/components/flowControl/ProductionSchedule.vue");
const FlowRules = () => import("@/components/flowControl/FlowRules.vue");
const PageParams = () => import("@/components/common/PageParams.vue");
import { handleItem, handleCell } from "@/components/flowControl/eventHandler.js";

import moment from "moment";
import {
  generateGanttItems,
  generateGanttItem,
  generateGanttRows,
} from "@/utils/flowControlGanttItemGenerator";
import { fetchProductData } from "@/services/flowControlService.js";
import { mapActions } from "vuex";
import _ from "lodash"; // lodash library for the debounce function

export default {
  components: {
    VueDjGantt,
    ProductSelect,
    DeliverySchedule,
    ProductionSchedule,
    FlowRules,
    PageParams,
    RowSupportMenu,
  },
  beforeMount() {
    this.hideDrawer();
  },
  mounted() {
    this.updateViewportHeight(); // Initially set to viewport height
    window.addEventListener("resize", this.updateViewportHeight);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateViewportHeight);
  },
  computed: {
    parameters: {
      get() {
        return this.$store.state.flowControlParameters.parameters;
      },
      set(value) {
        this.updateParameters(value);
      },
    },
    filteredRows() {
      if (this.rows && this.rows.length > 0) {
        const filterText = this.filterProductName
          ? this.filterProductName.toLowerCase()
          : ""; // Convert filter text to lowercase
        const filterTypes = ["balance", ...this.displayRows];

        return this.rows.filter(
          (r) =>
            r.productName.toLowerCase().includes(filterText) &&
            filterTypes.includes(r.type)
        );
      } else {
        return this.rows;
      }
    },

    filteredItems() {
      return this.items;
    },
  },
  data: () => ({
    loaded: false,
    loading: false,
    startDate: moment().startOf("day"), // Start date set to today
    endDate: moment().startOf("day").add(1, "months"), // End date set to a month from today
    dialog: false,
    inputMode: null,
    inputKey: null,
    inputProduct: null,
    viewportHeight: 0,
    panel: 0,
    selectedDelivery: null,
    selectedProduction: null,
    productList: [],
    productInventoryData: [],
    list: [
      {
        id: "name",
        width: 200,
        header: {
          content: "Product",
        },
      },
    ],
    filterProductName: null,
    displayRows: ["delivery", "production"],
    rows: [],
    items: [],
    zoom: 11,
  }),
  methods: {
    ...mapActions("messages", [
      "addErrorMessage",
      "addMessage",
      "addSuccessMessage",
      "clearMessage",
    ]),
    ...mapActions("flowControlParameters", ["updateParameters"]),

    ...mapActions("navigation", ["hideDrawer"]),
    updateViewportHeight() {
      this.viewportHeight = window.innerHeight;
    },
    updateChartProduct(product) {
      try {
        this.loading = true;

        // Find the index of the product in productInventoryData
        const productIndex = this.productInventoryData.findIndex(
          (p) => p.id === product.id
        );
        // Calculate row index (1-indexed and each product takes 3 rows)
        const rowIndex = productIndex * 3 + 1;

        // Regenerate the Gantt items for the updated product
        const updatedItems = generateGanttItem(product, rowIndex);

        // Update the component's items array
        this.items = this.items
          .filter((item) => item.productId !== product.id)
          .concat(updatedItems);
      } catch (error) {
        this.addErrorMessage(error.message);
        throw error; // re-throw the exception
      } finally {
        this.loading = false;
      }
    },

    onSubmit() {
      this.loading = true;

      try {
        // validate date range
        if (moment(this.startDate).isSameOrAfter(moment(this.endDate))) {
          this.addErrorMessage("End date must be later than Start date.");
          return;
        }

        this.initData();
      } catch (error) {
        this.addErrorMessage(error.message);
      } finally {
        this.loaded = true;
        this.panel = null;
        this.loading = false;
      }
    },

    async initData() {
      this.productInventoryData = await fetchProductData({
        selectedProducts: this.parameters.selectedProducts,
        startDate: this.startDate,
        endDate: this.endDate,
      });

      this.list[0].header.content = `Products (${this.productInventoryData.length})`;

      this.rows = generateGanttRows(this.productInventoryData);
      this.items = generateGanttItems(this.productInventoryData, this.rows);
    },

    async reloadProduct(productId) {
      debugger;
      const product = this.parameters.selectedProducts.find(
        (p) => p.id == productId
      );
      const productData = await fetchProductData({
        selectedProducts: [product],
        startDate: this.startDate,
        endDate: this.endDate,
      });

      const productRows = this.rows.filter((r) => r.productId == productId);
      const productRowIds = productRows.map((r) => r.id);
      const productItems = generateGanttItems(productData, productRows);

      // Filter out the old items that belong to the product
      this.items = this.items.filter(
        (item) => !productRowIds.includes(item.rowId)
      );

      // Append the new items to the list
      this.items = [...this.items, ...productItems];
    },
    initProductList(productList) {
      this.productList = productList;
    },

    zoomOut() {
      this.zoom = this.zoom - 1 || 8;
    },
    zoomIn() {
      this.zoom = this.zoom + 1 || 16;
    },
    // zoomChanged(zoom) {
    //   this.zoom = zoom;
    // },
    selectedRow(row) {
      this.dialog = true;
      this.inputMode = "product";
      this.inputKey = row.id;
      this.inputProduct = this.productInventoryData.find(
        (p) => p.id == row.productId
      );
    },
    doubleClickedItem(event) {
      const result = handleItem(event, this.productInventoryData);
      this.dialogTitle = result.dialogTitle;
      this.dialogData = result.dialogData;
      this.dialogMode = result.dialogMode;
      this.dialog = true;
    },
    doubleClickedCell(cell) {
      
      const result = handleCell(cell, this.productInventoryData);
      this.dialogTitle = result.dialogTitle;
      this.dialogData = result.dialogData;
      this.dialogMode = result.dialogMode;
      this.dialog = true;
    },
    onDialogSuccess(event) {
      this.dialog = false;

      // const type = event.type;
      // const data = event.data;

      this.reloadProduct(event.productId);
    },
    onDialogClose() {
      this.dialog = false;
    },
    openMenuDialog(event) {
      this.dialogTitle = event.dialogTitle;
      this.dialogData = event.dialogData;
      this.dialogMode = event.dialogMode;
      this.dialog = true;
    },
  },
};
</script>


<style scoped>
.bg-icon {
  opacity: 0.3;
  z-index: 1;
}
.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>