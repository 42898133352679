import Vue from 'vue';
import moment from 'moment';
import {
    getDeliveryScheduleByDates,
} from "@/services/deliveryScheduleService.js";
import {
    getProductionScheduleByDates,
} from "@/services/productionScheduleService.js";

const fetchProductData = async ({ selectedProducts, startDate, endDate }) => {
    const product2Balance = await fetchProductBalance({ products: selectedProducts, startDate });
    let productData = [];
    if (product2Balance) {

        // initialize product inventory balance
        productData = Object.entries(product2Balance).map(
            ([productId, productBalance]) => {
                const product = selectedProducts.find(p => p.id == productId)
                return {
                    id: Number(productId),
                    product,
                    balance: productBalance.quantity,
                    balanceDate: startDate,
                    serieEndDate: endDate,
                    maxProductionOverlap: 1,
                    maxDeliveryOverlap: 0,
                    deliveries: [],
                    productions: [],
                };
            }
        )
        // Set product meta data, assign deliveries and productions
        await initProductData(productData, startDate, endDate);
    }

    return productData;

}


const initProductData = async (data, startDate, endDate) => {

    const productIds = data.map(product => product.id)
    const allDeliveries = await getDeliveries(productIds, startDate, endDate);

    //Assign deliveries to this.productInventoryData
    assignDeliveries2ProductData(allDeliveries, data);

    const allProductions = await getProductions(productIds, startDate, endDate);

    //Assign productions to this.productInventoryData
    assignProductions2ProductData(allProductions, data);


    // update count to rows
    // data.forEach((p) => {
    //     updateRowItemCount(this.rows, p);
    // });

}


// const updateRowItemCount = (rows, product) => {
//     const deliveryRow = rows.find(
//       (r) => r.productId == product.id && r.type == "delivery"
//     );
//     deliveryRow.name = `Delivery (${
//       product.deliveries ? product.deliveries.length : 0
//     })`;
//     const productionRow = rows.find(
//       (r) => r.productId == product.id && r.type == "production"
//     );
//     productionRow.name = `Production (${
//       product.productions ? product.productions.length : 0
//     })`;
//   }



const getDeliveries = async (productIds, startDate, endDate) => {
    return await fetchDataHelper(productIds, startDate, endDate, getDeliveryScheduleByDates);
}

const getProductions = async (productIds, startDate, endDate) => {
    return await fetchDataHelper(productIds, startDate, endDate, getProductionScheduleByDates);
}


const assignDeliveries2ProductData = (deliveries, productData) => {
    assignData2Product(deliveries, productData, "delivery");
}

const assignProductions2ProductData = (productions, productData) => {
    assignData2Product(productions, productData, "production");
}


const assignData2Product = (schedules, productData, type) => {
    if (schedules) {
        schedules.forEach((item) => {
            // Find the product in the product data array
            const product = productData.find(
                (product) => product.id === item.productId
            );

            // If the product is found, push the item into the product's deliveries or productions array
            if (product) {
                if (type === "delivery") {
                    product.deliveries.push(item);
                } else if (type === "production") {
                    product.productions.push(item);
                }
            }
        });
    }
}

const fetchDataHelper = async (productIds, startDate, endDate, serviceFunction) => {
    const productIdsString = productIds.join(",");
    if (productIds && productIds.length > 0) {
        return await serviceFunction(
            productIdsString,
            moment(startDate).format("YYYY-MM-DD"),
            moment(endDate).format("YYYY-MM-DD")
        ).then((resp) => {
            return resp.data;
        });
    }
}

const fetchProductBalance = async ({ products, startDate }) => {

    if (products && products.length > 0) {
        const productIds = products.map((p) => p.id);
        const params = {
            productIds: productIds.join(","),
            inventoryDate: moment(startDate).format("YYYY-MM-DDTHH:mm:ss"),
            includeCost: false,
        };
        return await Vue.axios.get("inventory/balance", { params }).then((resp) => {
            const inventoryBalances = resp.data.data;
            return groupInventoryBalanceByProduct(inventoryBalances);
        });
    }
}


const groupInventoryBalanceByProduct = (inventories) => {
    return inventories.reduce(function (res, item) {
        if (!res[item.productId]) {
            res[item.productId] = {
                product: item.product,
                quantity: 0,
            };
            inventories.push(res[item.productId]);
        }

        res[item.productId].quantity += item.quantityRemaining;

        return res;
    }, {});
}


export { fetchProductData }